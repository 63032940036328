<!--
  删除规则弹框
-->
<template>
  <el-dialog
    :visible.sync="deleteRuleToProp"
    :title="$t('label.delete.rules')"
    :before-close="handleClose"
    :close-on-click-modal="false"
  >
    <div class="dialog-body">
      <svg class="icon" aria-hidden="true">
        <use href="#icon-danger"></use>
      </svg>
      <!-- 确认删除该条数据吗？ -->
      <div>{{ $t("label.weixin.confirm.delete") }}</div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="deleteRuleCancel">{{
        $t("button_partner_cancel")
      }}</el-button>
      <el-button type="primary" @click="deleteRuleConfirm">{{
        $t("label.ems.confirm")
      }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  props: {
    deleteRuleTo: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      deleteRuleToProp: this.deleteRuleTo,
    };
  },
  watch: {
    deleteRuleTo: {
      handler() {
        this.deleteRuleToProp = this.deleteRuleTo;
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    handleClose() {
      this.$emit("deleteRuleCancel");
    },
    deleteRuleCancel() {
      this.$emit("deleteRuleCancel");
    },
    deleteRuleConfirm() {
      this.$emit("deleteRuleConfirm");
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog {
  width: 552px;
}
.dialog-body {
  display: flex;
  align-items: center;
  padding: 10px 0;
  color: #080707;
  .icon {
    display: inline-block;
    margin-right: 10px;
  }
}
</style>
