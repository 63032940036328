<template>
  <div class="detailChart">
    <!-- 展示说明与案列图-->
    <div class="instructions">
      <div class="testAndAdd">
        <!-- 左侧文字说明 -->
        <div class="testInstru">
          <!-- 可以在记录详情页中增加图表模块，插入最多十个报表图表。
          例如，您可以为客户对象中，记录类型为代理商的记录添加报表图表模块，
          将代理商订单产品销售金额统计、代理商季度销售额报表的图表添加至模块内，
          并使用客户名称进行筛选，在代理商客户的详情页直接查看相关数据。 -->
          {{ $t("front-lightning-module-v1-details-page-chart-tittle") }}
        </div>
        <!-- 新建-->
        <div class="addNewThing" @click="addsomething">{{ $t("label.import.page1.newcreate") }}</div>
      </div>

      <!-- 右侧图例说明 -->
      <div class="legendInstru">
        <img
          class=""
          src="@/assets/detailsPageChart/detailspagechart.svg"
          alt=""
        />
      </div>
    </div>

    <!-- 表格-->
    <div class="chartForm">
      <el-table
        :data="chartFormData"
        :row-style="{ height: '40px' }"
        :cell-style="{ padding: '0px', color: '#333', fontSize: '14px' }"
        :header-cell-style="{
          background: '#F0F0F0',
          color: '#333',
          height: '40px',
          padding: '0',
          'border-right': '1px solid #dcdcdc',
        }"
        style="width: 100%"
        border
        :empty-text="$t('label.noRules.have.been.enabled')"
      >
      <!-- 操作 -->
        <el-table-column :label="$t('label.department.operation')">
          <template slot-scope="scope">
            <div class="operations">
              <!-- 编辑 -->
              <div @click="editRule(scope.row)">{{ $t("pagecreator.page.button.edit") }}</div>
              <div>|</div>
              <!-- 删除 -->
              <div @click="deleteRule(scope.row)">{{ $t("component.chatter.button.delete") }}</div>
            </div>
          </template>
        </el-table-column>
        <!-- 对象 -->
        <el-table-column prop="objectname" :label="$t('component.setup.label.object')"> </el-table-column>
        <!-- 记录类型 -->
        <el-table-column prop="recordtypename" :label="$t('label.appupload.component.type.recordtype')">
        </el-table-column>
        <!-- 报表名称 -->
        <el-table-column prop="reportname" :label="$t('front-lightning-module-v1-details-page-chart-report-name')"> </el-table-column>
      </el-table>
    </div>

    <!-- 删除规则弹框 -->
    <deleteRule
      :deleteRuleTo="deleteRuleTo"
      @deleteRuleCancel="deleteRuleCancel"
      @deleteRuleConfirm="deleteRuleConfirm"
    />
  </div>
</template>
<script>
import deleteRule from "./components/deleteRule.vue";
import * as pageChartApi from "../../systemSettingsApi.js";

export default {
  components: {
    deleteRule,
  },
  name: "detailsPageChart",
  data() {
    return {
      chartFormData: [],
      deleteRuleTo: false, //删除
      reportChartId: "", // 删除报表图表id
    };
  },
  mounted() {
    this.getReportChartList();
  },
  methods: {
    // 获取详情页报表图表全部列表信息
    async getReportChartList() {
      let res = await pageChartApi.getReportChartList();
      if (res.result) {
        this.chartFormData = res.data;
      }
    },
    //  新建
    addsomething() {
      this.$router.push({
        path: "ReportChart",
        query: {},
      });
    },
    // 编辑报表
    editRule(value) {
      // 
      this.$router.push({
          path: `ReportChart`,
          query: {
            reportId: value.id,
            isEditMode: value,
            isEdit: "true",
          },
        });
    },
    // 删除报表图表接口
    async removeReportChart() {
      let params = {
        id: this.reportChartId,
      };
      let res = await pageChartApi.removeReportChart(params);
      if (res.result) {
        // 删除后再次获取详情页报表图表全部列表信息
        this.getReportChartList();
      }
    },
    // 删除
    deleteRule(valid) {
      this.reportChartId = valid.id;
      this.deleteRuleTo = true;
    },
    // 取消"删除规则"
    deleteRuleCancel() {
      this.deleteRuleTo = false;
    },
    // 确定"删除规则"
    deleteRuleConfirm() {
      // 删除报表图表
      this.removeReportChart();
      this.deleteRuleTo = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.detailChart {
  width: 100%;
  height: 100%;
  padding: 15px 20px 0 20px;
  .instructions {
    width: 100%;
    display: flex;
    box-sizing: border-box;
    justify-content: space-between;
    .testAndAdd {
      // position: relative;
      width: 40%;
      .testInstru {
        font-size: 16px;
        color: #333333;
      }
      .addNewThing {
        // position: absolute;
        // bottom: 0;
        margin-top: 20px;
        border-radius: 3px;
        width: 68px;
        height: 33px;
        line-height: 33px;
        background: #006dcc;
        color: white;
        text-align: center;
        cursor: pointer;
      }
    }
    .legendInstru {
      width: 58%;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .chartForm {
    margin-top: 30px;
    .operations {
      display: flex;
      color: #006dcc;
      div {
        margin-right: 5px;
        cursor: pointer;
      }
    }
  }
}
</style>